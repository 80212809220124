import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { AddActionButton, PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import JsonViewer from "./common/jsonViewer";
import DataTable from "./dataTable";
import { useServerApi } from "../hooks/useServerApi";
import AddNewButton from "./addNewButton";
import { useFormRender } from "../hooks/useFormRender";
import RelatedDataTable from "./relatedDataTable";

const ProjectQuotationList = ({ form: mainForm, ...props }) => {
  const [api] = useServerApi();
  const [data, setData] = useState([]);
  const [getFormSchema] = useFormSchema();
  const [getTableSchema] = useTableSchema();

  //Form
  const projectId = _.get(mainForm.values, "_id");
  const clientId =
    _.get(mainForm.values, "client._id") ?? _.get(mainForm.values, "client");
  const customerId = _.get(mainForm.values, "customer");
  const formSchema = {
    ...getFormSchema("ProjectQuotation"),
    initialValues: {
      project: projectId,
      client: clientId,
      customer: customerId,
      terms: [{ title: "", detail: "" }],
    },
  };

  //Table
  const tableSchema = getTableSchema("ProjectQuotationList", {
    projectId,
  });

  return (
    <>
      <RelatedDataTable
        tableSchema={tableSchema}
        formSchema={formSchema}
        mainForm={mainForm}
        apiEntity={"quotation"}
        fieldIn="_id"
        fieldOut="project"
      />
    </>
  );
};

export default ProjectQuotationList;
