import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { DateInput } from "@mantine/dates";
import remoteSelect2 from "../../components/remoteSelect2";
import { TextInput, NumberInput } from "@mantine/core";
import FileList from "../../components/fileList";

const initialValues = {};

export const ClaimFormSchema = {
  name: "addSchema",
  title: "Claim",
  description: "Please follow the step to create a New Claim",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "1000px", //xs, sm, xl  ....100%
  },

  apiEntity: "claim",
  initialValues,

  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Records",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Records",
          },

          {
            key: "tab-file",
            label: "Files",
          },
        ],
      },
    ],
    fields: [
      {
        name: "user",
        component: remoteSelect2,
        parent: "tab-overview",

        props: {
          label: "Staff",
          apiEntity: "user",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "project",
        component: remoteSelect2,
        parent: "tab-overview",

        props: {
          label: "Project for Claim",
          apiEntity: "project",
          required: true,
          valueField: "_id",
          labelRender: (data) => `[ ${data.code} ]  ${data.name}`,
          searchFields: ["name", "code"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "transactionDate",
        component: DateInput,
        type: "datePicker",
        parent: "tab-overview",
        props: {
          required: true,
          label: "Transaction Date",
          placeholder: "",
        },
      },

      {
        name: "amount",
        component: NumberInput,
        parent: "tab-overview",

        props: {
          label: "Amount(HKD)",
          thousandSeparator: ",",
          precision: 2,
          required: true,
        },
      },

      {
        name: "claimType",
        component: remoteSelect2,
        parent: "tab-overview",

        props: {
          label: "Claim Type",
          apiEntity: "claimType",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "description",
        component: TextInput,
        parent: "tab-overview",

        props: {
          label: "Description",
          placeholder: "",
        },
      },
      {
        name: "files",
        component: FileList,
        parent: "tab-file",
      },
    ],
  },
};
