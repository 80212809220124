import React, { useState, useEffect } from "react";
import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useServerApi } from "../hooks/useServerApi";
import { Badge, Button, Group, Text } from "@mantine/core";
import ProductionItemListRow from "./productionItemListRow";
import OrderList from "./orderList";
import { useTranslation } from "react-i18next";
import { miscItemAddForm } from "../schema/form/item";
import { useForceUpdate } from "@mantine/hooks";

import { fetchItemOptions } from "./productionSectionGroup";

const getProductionItems = (sections) => {
  const items = [];
  sections?.forEach((section) => {
    section.itemGroups.forEach((group) => {
      items.push(...group.itemList);
    });
  });
  return items;
};

const ItemSummaryList = ({ form }) => {
  const itemList = _.get(form.values, "itemList") || [];
  const reservedItemList = _.get(form.values, "reservedItemList") || [];
  const itemReserved = _.get(form.values, "itemReserved") || [];
  const [api] = useServerApi();
  const [itemSelectOptions, setItemSelectOptions] = useState([]);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const options = await fetchItemOptions(api);
      setItemSelectOptions(options);
    } catch (error) {
      console.log(error);
    }
  };
  const copyItemsFromProduction = () => {
    try {
      const productionSections = form.values.productionSections || [];
      //Group Items with _id and sum up quantity
      const nonProductionItems =
        itemList.filter((item) => !item.isProduction) ?? [];

      const items = getProductionItems(productionSections);
      if (!items || _.isEmpty(items)) return;

      console.log("items", items);

      const groupedItems = [];
      items.forEach((item) => {
        const existingItem = groupedItems.find((i) =>
          _.isObject(i.item)
            ? i.item._id === item.item._id
            : i.item === item.item
        );
        if (existingItem) {
          existingItem.quantity += item.quantity;
        } else {
          groupedItems.push({
            item: _.isObject(item.item) ? item.item._id : item.item,
            quantity: item.quantity,
            isProduction: true,

            unit: item.unit,
            remark: item.remark,
          });
        }
      });

      // const groupedItems = _.chain(items)
      //   .groupBy(_.isObject(items[0].item) ? "item._id" : "item")
      //   .map((items, _id) => ({
      //     item: _id,
      //     quantity: _.sumBy(items, "quantity"),
      //     isProduction: true,
      //     unit: _.get(items, "[0].unit"),
      //     remark: _.get(items, "[0].remark"),
      //   }))
      //   .value();

      console.log("groupedItems", groupedItems);

      form.setFieldValue("itemList", [...nonProductionItems, ...groupedItems]);
    } catch (error) {
      console.log(error);
    }
  };

  const printItemList = async () => {
    try {
      // console.log("printItemList");
      if (!form.values._id) return;
      const result = await api.Project.printItemList(form.values._id);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const bookItems = async () => {
    try {
      await form.save();
      const result = await api.Project.reserveItems(form.values._id);
      if (!result) throw "No result when booking items";
      if (result.data && result.data.itemReserved) {
        form.setFieldValue("itemReserved", result.data.itemReserved);
        form.setFieldValue("reservedItemList", form.values.itemList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelBooking = async () => {
    try {
      const result = await api.Project.cancelBooking(form.values._id);
      if (!result) throw "No result when cancel booking items";
      if (result.data && result.data.done) {
        form.setFieldValue("reservedItemList", []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const isReserved =
    _.isEqual(itemList, reservedItemList) && !_.isEmpty(itemList);

  const { t } = useTranslation();
  return (
    <>
      {/* <JsonViewer src={itemList} /> */}
      {/* <JsonViewer src={reservedItemList} /> */}
      <Group justify="space-between" mb="xl">
        <Group>
          <Button
            onClick={() => copyItemsFromProduction()}
            size="xs"
            variant="default"
          >
            {t("Copy Production")}
          </Button>

          {isReserved && (
            <Button
              onClick={() => cancelBooking()}
              size="xs"
              variant="default"
              c="red"
            >
              {t("Cancel Booking")}
            </Button>
          )}
          {!isReserved && !_.isEmpty(itemList) && (
            <Button onClick={() => bookItems()} size="xs" variant="default">
              {t("Book Items")}
            </Button>
          )}
        </Group>
        <Button onClick={() => printItemList()} size="xs" variant="default">
          {t("Print")}
        </Button>
      </Group>
      {!_.isEmpty(itemList) && (
        <Badge radius={0} mb="md" color={isReserved ? "green" : "red"}>
          {isReserved ? "Items booked" : "Items are not booked yet"}
        </Badge>
      )}

      <OrderList
        form={form}
        name={`itemList`}
        defaultRowValue={{
          quantity: 1,
          unit: "PCS",
        }}
        row={{ component: ProductionItemListRow, data: itemSelectOptions }}
        addLabel="Add Item"
        allowAddNew={true}
        addNewItemLabel="New Custom Item"
        addNewItemFormSchema={miscItemAddForm}
        onAddNewSubmitSuccess={(result) => {
          if (result && result.values) {
            const newItem = {
              item: result.values._id,
              quantity: 1,
              unit: "PCS",
            };
            form.setFieldValue(`itemList`, [
              ..._.get(form.values, `itemList`),
              newItem,
            ]);
            fetchItems();
          }
        }}
      />
    </>
  );
};

export default ItemSummaryList;
