import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { AddActionButton, PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import JsonViewer from "./common/jsonViewer";
import DataTable from "./dataTable";
import { useServerApi } from "../hooks/useServerApi";
import AddNewButton from "./addNewButton";
import { useFormRender } from "../hooks/useFormRender";
import RelatedDataTable from "./relatedDataTable";

const ProjectInvoiceList = ({ form: mainForm, ...props }) => {
  const [api] = useServerApi();
  const [data, setData] = useState([]);
  const [getFormSchema] = useFormSchema();
  const [getTableSchema] = useTableSchema();

  const apiEntity = "invoice";
  //Form
  const projectId = _.get(mainForm.values, "_id");
  const clientId =
    _.get(mainForm.values, "client._id") ?? _.get(mainForm.values, "client");
  const customerId = _.get(mainForm.values, "customer");
  const formSchema = {
    ...getFormSchema("ProjectInvoice"),
    initialValues: {
      project: projectId,
      client: clientId,
      customer: customerId,
      terms: [{ title: "", detail: "" }],
    },
  };

  //Table
  const tableSchema = getTableSchema("ProjectInvoiceList", {
    projectId,
  });

  const fetchData = async () => {
    try {
      if (!projectId) return;
      const result = await api.search({
        apiEntity: "invoice",
        searchQuery: { project: projectId },
      });
      if (result.docs) setData(result.docs);
    } catch (error) {
      console.log(error);
    }
  };

  //after succes add
  const onSubmitSuccess = () => {
    fetchData();
  };

  //After delete
  const postDelete = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [projectId]);

  const copyToNew = async (data) => {
    const newData = { ...data };
    delete newData._id;
    delete newData.createdAt;
    if (newData.code) {
      newData.code += "(copy)";
    }
    let result = await api.add({ apiEntity, values: newData });
    return result?.data;
  };

  const onActionBtnClick = async ({ action, data }) => {
    // console.log("onActionBtnClick", action, data);
    const { name: actionName, linkPath: actionLinkPath } = action;
    switch (actionName) {
      case "show":
        formAction.open({ mode: "show", id: data._id });
        break;
      case "edit":
        // console.log("location edit", `${location.pathname}?id=${data._id}`);
        // formAction.openIdByLink({ mode: "edit", id: data._id });
        formAction.open({ mode: "edit", id: data._id });
        break;
      case "copy":
        const newRecord = await copyToNew(data);
        formAction.open({ mode: "edit", id: newRecord._id });
        break;

      case "openEdit":
        // formAction.open({ mode: "edit", id: data._id });
        window.open(`${actionLinkPath}?id=${data._id}&mode=edit`, "_blank");
        break;

      case "delete":
        // console.log("delete", data._id);
        const confirm = window.confirm(
          `Are you sure to delete the data ${data.code}?`
        );
        if (confirm) {
          await api.removeById({ apiEntity, id: data._id });
          fetchData();
          postDelete();
        }
        break;
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess
  );

  const addNew = () => {
    if (!formSchema) return;
    // console.log("add new", formSchema);
    formAction.open({ mode: "add" });
  };

  return (
    <>
      <RelatedDataTable
        tableSchema={tableSchema}
        formSchema={formSchema}
        mainForm={mainForm}
        apiEntity={"invoice"}
        fieldIn="_id"
        fieldOut="project"
      />
    </>
  );
};

export default ProjectInvoiceList;
