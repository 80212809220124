import React, { useRef, useState, useEffect } from "react";
import FormSection from "./formSection";
import OrderList from "./orderList";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import {
  Card,
  Group,
  NumberInput,
  Button,
  Tabs,
  TextInput,
  InputWrapper,
  Select,
  Space,
} from "@mantine/core";

import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";
import FileList from "./fileList";
import JsonViewer from "./common/jsonViewer";
import { useServerApi } from "../hooks/useServerApi";
import ItemsFloorPlan from "./itemsFloorPlan";
import FloorPlanLayoutTable from "./floorPlanLayoutTable";
import ProductionItemListRow from "./productionItemListRow";
import { useFormSchema } from "../hooks/useFormSchema";
import ProductionItemResourceLinkRow from "./productionItemResourceLinkRow";
import { miscItemAddForm } from "../schema/form/item";
import { showNotification } from "@mantine/notifications";

export const fetchItemOptions = async (api) => {
  try {
    const maxItemLabelLength = 35;
    const r = await api.search({
      apiEntity: "item",
      pageSize: 5000,
      sort: { by: "name", order: "asc" },
      select: "name code cname description unitsOfMeasure category _id",
    });
    if (!r.docs) return;

    let list = r.docs?.map((d) => ({
      value: d._id,
      label:
        d.name && d.name.length > maxItemLabelLength
          ? d.name.substring(0, maxItemLabelLength) + "..."
          : d.name,
      category: d.category?.name,
      description: d.description,
      unitsOfMeasure: d.unitsOfMeasure,
    }));

    //Rank list items by label
    list.sort((a, b) => a.label.localeCompare(b.label));

    const options = [];
    for (const item of list) {
      const o = options.find((o) => o.group === item.category);
      if (!o) {
        options.push({ group: item.category, items: [item] });
      } else {
        o.items.push(item);
      }
    }

    //Rank the options  by group name and Misc items at the end
    options.sort((a, b) => {
      if (a.group === "Miscellaneous") return 1;
      if (b.group === "Miscellaneous") return -1;
      return a.group.localeCompare(b.group);
    });

    //Attach count (n) to the group name
    options.forEach((o) => {
      o.group = `${o.group} (${o.items.length})`;
    });

    //Limit the label length with end ellipsis
    options.forEach((o) => {
      o.items.forEach((i) => {
        i.label =
          i.label.length > 30 ? i.label.substring(0, 30) + "..." : i.label;
      });
    });

    return options;
    // setItemSelectOptions(options);
    // console.log("selectOptions= ", options);
  } catch (error) {
    console.log("error", error);
  }
};
const ProductionSectionGroup = ({ group, form, sectionIndex, groupIndex }) => {
  const forceUpdate = useForceUpdate();
  const formItemGroupName = `productionSections.${sectionIndex}.itemGroups.${groupIndex}`;
  const catOptions = _.get(form.values, `${formItemGroupName}.catOptions`);
  const [api] = useServerApi();
  const { t } = useTranslation();
  const [getFormSchema] = useFormSchema();
  const [isLoadingAuto, setIsLoadingAuto] = useState(false);
  const [itemSelectOptions, setItemSelectOptions] = useState([]);
  // const itemAddForm = getFormSchema("item").add;
  // itemAddForm.display.mode = ENUM_FORM_DISPLAY.DRAWER;

  const autoPlan = async (group) => {
    try {
      // console.log("Autoplan", group);
      // form.setFieldValue(`${formItemGroupName}.itemList`, []);

      setIsLoadingAuto(true);
      const result = await api.ItemGroup.autoPlan(group);
      // console.log(result);
      if (result.error) throw result.error;
      if (!result || !result.data) throw "No result data";

      const newItemList = [];
      const newFloorPlan = [];
      for (const r of result.data.results) {
        // console.log(r);
        //Single Item
        if (r.item) {
          newItemList.push(r);
          continue;
        }
        //Combination result (hasve Selection)
        if (r.combination) {
          //Item List
          const optimized = r.combination[0];
          if (!optimized) continue;
          optimized.typeGroup.forEach((typeGroup) => {
            typeGroup.unit = r.unit;
            typeGroup.remark = r.remark;
          });
          newItemList.push(...optimized.typeGroup);
          //Floor Plan (layout)
          newFloorPlan.push(...optimized.items);
          newFloorPlan.forEach((plan) => {
            plan.category = r.category;
            plan.dim1 = r.dim1;
            plan.dim2 = r.dim2;
          });
          continue;
        }
      }
      // const newItemList = result.data?.results.map((r) => ({
      //   category: r.category ?? "ABC",
      //   item: r.item?._id ?? null,
      //   quantity: r.quantity,
      // }));
      console.log(newItemList);

      const floorPlans = _.groupBy(newFloorPlan, "category._id");
      // console.log(floorPlans);

      form.setFieldValue(`${formItemGroupName}.itemList`, []);
      form.setFieldValue(`${formItemGroupName}.itemList`, newItemList);
      form.setFieldValue(`${formItemGroupName}.floorPlan`, {});
      form.setFieldValue(`${formItemGroupName}.floorPlan`, floorPlans);

      // await fetchItemOptions(api);
      // forceUpdate();
      setIsLoadingAuto(false);
    } catch (error) {
      console.log(error);
      setIsLoadingAuto(false);
      showNotification({
        title: "Auto Plan Error",
        message: error.error || error.message || error,
        color: "red",
      });
    }
  };

  const getGroupUnit = (unit) => (unit ? "(" + unit + ")" : "");

  const fetchItems = async () => {
    try {
      const options = await fetchItemOptions(api);
      setItemSelectOptions(options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Tabs.Panel value={groupIndex + ""}>
      {/* <JsonViewer src={catOptions} /> */}
      <Card title={group.name} mb="xl">
        <Group align="end" grow mb="sm">
          <TextInput
            value={group.name}
            label={t("Group Name")}
            size="xs"
            {...form.getInputProps(`${formItemGroupName}.name`)}
          />
          <InputWrapper label={t("Group Type")} size="xs" my={0}>
            <RemoteSelect2
              size="xs"
              apiEntity="itemGroup"
              labelField="name"
              valueField="_id"
              labelRender={(data) => `${data.name}`}
              multiple={false}
              searchFields={["name", "code"]}
              placeholder="Item"
              clearable={true}
              value={_.get(form.values, `${formItemGroupName}.groupType`)}
              onDataChange={(data) => {
                form.setFieldValue(
                  `${formItemGroupName}.catOptions`,
                  // data
                  data?.autoAssignRules
                    ?.map((r) => ({
                      name: r.itemCategory.name,
                      catId: r.itemCategory._id,
                      options: r.itemCategory.options,
                    }))
                    .filter((r) => r.options && r.options.length > 0)
                );
                //Set group Type Id
                form.setFieldValue(
                  `${formItemGroupName}.groupType`,
                  data ? data._id : null
                );
                form.setFieldValue(
                  `${formItemGroupName}.groupUnit`,
                  data ? data.groupUnit : null
                );
              }}
              sort={{
                by: "name",
                order: "asc",
              }}
            />
          </InputWrapper>
        </Group>

        {/* Group Dimension */}
        {/* <JsonViewer src={group} /> */}
        <Group grow align="end">
          <NumberInput
            value={group.dimensions.width}
            label={`${t("Width")} ${getGroupUnit(group.groupUnit)}`}
            thousandSeparator=","
            size="xs"
            {...form.getInputProps(`${formItemGroupName}.dimensions.width`)}
          />
          <NumberInput
            value={group.dimensions.depth}
            label={`${t("Depth")} ${getGroupUnit(group.groupUnit)}`}
            thousandSeparator=","
            size="xs"
            {...form.getInputProps(`${formItemGroupName}.dimensions.depth`)}
          />
          <NumberInput
            value={group.dimensions.height}
            label={`${t("Height")} ${getGroupUnit(group.groupUnit)}`}
            thousandSeparator=","
            size="xs"
            {...form.getInputProps(`${formItemGroupName}.dimensions.height`)}
          />
          <Button
            variant="default"
            onClick={async () => {
              await autoPlan(group);
              // await form.save();
              // form.reload();
              // forceUpdate();
            }}
            loading={isLoadingAuto}
          >
            {t("Auto Plan")}
          </Button>
        </Group>

        {catOptions && catOptions.length > 0 && (
          <Group mt="md">
            {catOptions?.map((catOption, catIndex) => (
              <span>
                {catOption.options.map((option, optionIndex) => (
                  <Select
                    size="xs"
                    label={`${catOption.name} [ ${option.name} ]`}
                    data={option.tags.map((tag) => tag)}
                    {...form.getInputProps(
                      `${formItemGroupName}.catOptions.${catIndex}.options.${optionIndex}.value`
                    )}
                  />
                ))}
              </span>
            ))}
          </Group>
        )}

        {/* Item List */}
        <Tabs defaultValue={"Item List"} mt={"xl"}>
          {/* <JsonViewer src={_.get(form.values, `${formItemGroupName}`)} /> */}

          <Tabs.List>
            <Tabs.Tab value="Item List">{t("Item List")}</Tabs.Tab>
            <Tabs.Tab value="Floor Plan">{t("Floor Plan")}</Tabs.Tab>
            <Tabs.Tab value="Linkage">{t("Linkage")}</Tabs.Tab>
            <Tabs.Tab value="Files">{t("Files")}</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="Item List">
            {/* <JsonViewer
                src={_.get(form.values, `${formItemGroupName}.itemList`)}
                name="itemList"
              /> */}
            <Card>
              <OrderList
                form={form}
                // name={name + ".itemList"}
                name={`${formItemGroupName}.itemList`}
                row={{
                  component: ProductionItemListRow,
                  data: itemSelectOptions,
                }}
                addLabel="Add Item"
                defaultRowValue={{
                  quantity: 1,
                  unit: "PCS",
                }}
                addNewItemLabel="New Custom Item"
                allowAddNew={true}
                addNewItemFormSchema={miscItemAddForm}
                onAddNewSubmitSuccess={(result) => {
                  // console.log(result);
                  // console.log(
                  //   _.get(form.values, `${formItemGroupName}.itemList`)
                  // );
                  if (result && result.values) {
                    const newItem = {
                      item: result.values._id,
                      quantity: 1,
                      unit: "PCS",
                    };
                    form.setFieldValue(`${formItemGroupName}.itemList`, [
                      ..._.get(form.values, `${formItemGroupName}.itemList`),
                      newItem,
                    ]);
                    fetchItems();
                  }
                }}
              />
            </Card>
          </Tabs.Panel>

          <Tabs.Panel value="Floor Plan">
            <Card>
              {/* <JsonViewer src={_.get(form.values, `${formItemGroupName}`)} /> */}

              <FloorPlanLayoutTable
                itemGroup={_.get(form.values, `${formItemGroupName}`)}
              />
              {/* <ReactJson src={_.get(form.values, `${formItemGroupName}`)} /> */}
              <Space h="md" />
              <ItemsFloorPlan
                itemGroup={_.get(form.values, `${formItemGroupName}`)}
              />
            </Card>
          </Tabs.Panel>

          <Tabs.Panel value="Linkage">
            <Card>
              <OrderList
                form={form}
                name={`${formItemGroupName}.linkages`}
                row={{ component: ProductionItemResourceLinkRow }}
                addLabel={t("Add Linkage")}
              />
            </Card>
          </Tabs.Panel>

          <Tabs.Panel value="Files">
            <Card>
              <FileList
                form={form}
                name={`${formItemGroupName}.files`}
              ></FileList>
            </Card>
          </Tabs.Panel>
        </Tabs>
      </Card>
    </Tabs.Panel>
  );
};

export default ProductionSectionGroup;
