import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  geocodeByLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

import _ from "lodash";
import JsonViewer from "./common/jsonViewer";
import {
  Text,
  Input,
  useComputedColorScheme,
  useMantineTheme,
  Group,
  Box,
  Button,
} from "@mantine/core";
import { IconClearAll, IconTrash } from "@tabler/icons-react";
import { useForceUpdate } from "@mantine/hooks";

const LocationAutocomplete = ({
  form,
  name,
  label,
  country = ["hk"],
  language = "zh-hk",
  region = "region",
}) => {
  const location = _.get(form.values, name);
  const [value, setValue] = useState(location?.e);
  const colorScheme = useComputedColorScheme();
  const theme = useMantineTheme();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (_.isEmpty(location)) return;
    if (!location.value) return;
    // console.log(location);
    setValue(location);
  }, [location]);

  const clearValue = () => {
    // console.log("clearValue");
    setValue(null);
    form.setFieldValue(name, null);
    form.setFieldValue("address", "");
    forceUpdate();
  };

  const handleOnChange = async (e) => {
    // console.log("location change ", e);
    try {
      setValue(e);
      // if (!e || !e.value || !e.value.place_id) return;
      console.log(e);
      let result = await geocodeByPlaceId(e?.value?.place_id);
      if (result.length) {
        const location = result[0];
        location.e = e;
        location.label = e.label;
        location.center = {
          lat: result[0].geometry.location.lat(),
          lng: result[0].geometry.location.lng(),
        };
        delete location.geometry;
        // setValue(location);
        form.setFieldValue(name, location);
        form.setFieldValue("address", location.formatted_address);
        // console.log(location);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Input.Wrapper label={label}>
      {/* <JsonViewer src={location} /> */}
      {/* colorScheme :{colorScheme} */}
      {/* <Group justify="space-between" grow> */}

      <GooglePlacesAutocomplete
        isClearable={true}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        apiOptions={{ language, region }}
        zIndex={9999}
        onKeyPress={(e) => {
          console.log("onKeyPress", e);
        }}
        selectProps={{
          value: location?.e,
          onChange: handleOnChange,
          // theme: "dark",
          styles: {
            control: (base, state) => ({
              ...base,
              background:
                colorScheme === "dark"
                  ? "#2e2e2e"
                  : "var(--mantine-color-body)",
              borderColor: "var(--mantine-color-default-border)",
              // ? "var(--mantine-color-default-border)"
              // : "var(--mantine-color-default-border)",
            }),
            dropdownIndicator: (base) => ({
              ...base,
              fontFamily: "var(--mantine-font-family)",
              fontSize: "var(--mantine-font-size-xs)",
              color: "var(--mantine-font-color)",
            }),
            indicatorSeparator: (base) => ({
              ...base,
              backgroundColor: "var(--mantine-font-color)",
            }),

            menu: (base) => ({
              ...base,
              background:
                colorScheme === "dark"
                  ? "#2e2e2e"
                  : "var(--mantine-color-body)",
              borderColor: "var(--mantine-color-default-border)",
            }),

            valueContainer: (base) => ({
              ...base,
              background:
                colorScheme === "dark"
                  ? "#2e2e2e"
                  : "var(--mantine-color-body)",
              borderColor: "var(--mantine-color-default-border)",
            }),

            input: (provided, state) => ({
              ...provided,
              color: "var(--mantine-font-color)",
              fontFamily: "var(--mantine-font-family)",
              fontSize: "var(--mantine-font-size-xs)",
              borderColor: "var(--mantine-color-default-border)",
            }),
            option: (provided) => ({
              ...provided,
              color: "var(--mantine-font-color)",
              fontFamily: "var(--mantine-font-family)",
              fontSize: "var(--mantine-font-size-xs)",
              borderColor: "var(--mantine-color-default-border)",
              backgroundColor:
                colorScheme === "dark"
                  ? "#2e2e2e"
                  : "var(--mantine-color-body)",
              "&:hover": {
                ...provided,
                color: "var(--mantine-font-color)",
                fontFamily: "var(--mantine-font-family)",
                fontSize: "var(--mantine-font-size-xs)",
                backgroundColor: "var(--mantine-color-default-border)",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "var(--mantine-font-color)",
              fontFamily: "var(--mantine-font-family)",
              fontSize: "var(--mantine-font-size-xs)",
              borderColor: "var(--mantine-color-default-border)",
            }),
          },
        }}
        autocompletionRequest={{
          componentRestrictions: { country },
        }}
      />

      {/* <Box width={10}>
          <IconClearAll cursor={"pointer"} onClick={() => clearValue()} />
        </Box> */}
      {/* </Group> */}

      {/* <Text c="dimmed" mt="md">
        {location?.label} [{location?.center?.lat} {location?.center?.lng}]
      </Text> */}
    </Input.Wrapper>
  );
};

export default LocationAutocomplete;
