import React, { useState } from "react";
import {
  Text,
  Group,
  Table,
  NumberInput,
  Checkbox,
  Badge,
  Button,
  ActionIcon,
  Divider,
} from "@mantine/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@mantine/hooks";
import { useServerApi } from "../hooks/useServerApi";

const GoogleCalendarBar = ({ form }) => {
  const { t } = useTranslation();
  const [api] = useServerApi();
  const projectId = _.get(form.values, "_id");
  const { googleEventId } = form.values;
  const [loading, setLoading] = useState(false);

  //get local storage token of google
  const [token, setToken] = useLocalStorage({
    key: "one2work-googleCalendar",
    defaultValue: null,
  });

  const unlink = async () => {
    try {
      setLoading(true);
      setToken(null);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const authGoogle = async (redirectUrl) => {
    try {
      //get current url
      const redirectUrl = window.location.href;

      const result = await api.GoogleCalendar.auth(redirectUrl);
      console.log(result);
      if (!result || !result.data) throw "No data returned from Google API.";
      console.log("Open auth url", result.data.authUrl);
      window.open(result.data.authUrl, "_blank");
      //window.location.href = result.data.authUrl;
      setToken(result.data.token);
    } catch (error) {
      console.log(error);
    }
  };

  const syncGoogle = async () => {
    try {
      setLoading(true);
      await form.save();

      let result = await api.Project.syncScheduleToGoogleCalendar(
        projectId,
        token
      );
      if (!result.success) {
        //Retry auth if token expired
        try {
          await authGoogle();
          result = await api.Project.syncScheduleToGoogleCalendar(
            projectId,
            token
          );
        } catch (error) {
          setLoading(false);
          setToken(null);
        }
      }

      setLoading(false);
      if (!result.success) throw result.error;
      if (!result.data || !result.data.googleEventId) return;
      form.setFieldValue("googleEventId", result.data.googleEventId);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Group justify="space-between">
      <Badge color={googleEventId ? "blue" : "red"} variant="filled" radius={0}>
        {googleEventId ? t("Synced") : t("Not Synced")}
      </Badge>
      <Group>
        {token && (
          <Button
            disabled={loading}
            size="xs"
            variant="default"
            onClick={syncGoogle}
          >
            {t("Sync Events to Google Calendar")}
          </Button>
        )}
        {token && (
          <Button
            loading={loading}
            size="xs"
            variant="default"
            onClick={unlink}
            c="red"
          >
            {t("Unlink")}
          </Button>
        )}
      </Group>
      {!token && (
        <Button size="xs" variant="default" onClick={authGoogle}>
          {t("Authorize Google")}
        </Button>
      )}
    </Group>
  );
};
export default GoogleCalendarBar;
