import React, { useRef } from "react";
import { useFormRender } from "../hooks/useFormRender";
import { Button, Group } from "@mantine/core";
import _ from "lodash";

import { useAuthUser } from "react-auth-kit";
import { useFormSchema } from "../hooks/useFormSchema";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";

const OrderClientSelect = ({
  form: mainForm,
  name,
  showInput = true,
  label = "Client",
}) => {
  const auth = useAuthUser();
  const [getFormSchema] = useFormSchema();
  const client = _.get(mainForm.values, name);
  const forceUpdate = useForceUpdate();
  const selectRef = useRef(null);

  const formSchema = getFormSchema("client").add;

  const handleNew = () => {
    formAction.open({ mode: "add" });
  };

  const handleEdit = () => {
    formAction.open({ mode: "edit", id: client._id ?? client });
  };

  const onSubmitSuccess = ({ values }) => {
    if (!values) return;
    // console.log("onSubmitSuccess ", values);
    mainForm.setFieldValue(name, values._id);

    formAction.close();
    selectRef.current?.refresh();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess
  );

  return (
    <>
      {renderForm()}
      {/* {formStatus.mode} */}
      <RemoteSelect2
        ref={selectRef}
        label={label}
        apiEntity="client"
        valueField="_id"
        labelField="name"
        searchFields={["name"]}
        value={client}
        sort={{ by: "name", order: "asc" }}
        onDataChange={(data) => {
          mainForm.setFieldValue(name, data ? data._id : null);
        }}
      />
      <Group mt="xs">
        <Button size="xs" onClick={() => handleNew()}>
          New
        </Button>
        <Button
          size="xs"
          onClick={() => handleEdit()}
          disabled={!client}
          color="green"
        >
          Edit
        </Button>
      </Group>
    </>
  );
};

export default OrderClientSelect;
